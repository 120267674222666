const initialState = {
    posts: undefined,
    allPosts: [],
    loading: false
  };
const initialScheduleReviews = {
    loading: true,
    scheduled_reviews: undefined
}
export const getPagesReducer = (state = false, action) => {
    switch(action.type){
        case 'GET_PAGES':
            return{
                ...state,
                pages: action.payload,
            }
        case 'DELETE_PAGES':
            return{
                ...state,
                pages: action.payload,
            }
        default:
            return{
                ...state
            }
    }
}
export const getMetaTagsReducer = (state = {}, action) => {
    switch(action.type){
        case 'GET_META_TAGS':
            return{
                ...state,
                meta_tags: action.payload,
                loading: false
            }
        case 'LOADING_META_TAGS':
            return{
                ...state,
                loading: true
            }
        default:
            return{
                ...state
            }
    }
}

export const getSocialPosts = (state = initialState, action) => {
    switch(action.type){
        case 'GET_SOCIAL_POSTS':
            return{
                ...state,
                posts: action.payload.data,
                allPosts: action.payload.data,
                loading: false
            }
        case 'CLEAR_SOCIAL_POSTS':
            return{
                ...state,
                loading: true,
            }
        case 'FILTER_POSTS_BY_STATUS':
            return{
                ...state,
                posts: state.allPosts.filter(post => post.status === action.payload)
            }
        default:
            return{
                ...state
            }
    }
}
export const getFacebookAccounts = (state = {}, action) => {
    switch(action.type){
        case 'GET_FACEBOOK_ACCOUNTS':
            return{
                ...state,
                accounts: action.payload
            }
        default:    
            return{
                ...state
            }
    }
}
export const getSocialScheduledReviews = (state = initialScheduleReviews, action) => {
    switch(action.type){
        case 'GET_SOCIAL_REVIEWS' :
            return{
                ...state,
                scheduled_reviews: action.payload,
                loading: false
            }
        case 'LOADING_SOCIAL_REVIEWS':
            return {
                ...state,
                loading: true
            }
        case 'ADD_NEW_REVIEW_SCHEDULE':
            return {
                ...state,
                scheduled_reviews: {
                    ...state.scheduled_reviews,
                    data: [...state.scheduled_reviews.data, action.payload]
                }
            };
        case 'EDIT_REVIEW_SCHEDULE':
            return {
                ...state,
                scheduled_reviews: {
                    ...state.scheduled_reviews,
                    data: state.scheduled_reviews.data.map(review =>
                        review.id === action.payload.id ? action.payload : review // Update review if ID matches
                    )
                }
            }; 
        case 'DELETE_REVIEW_SCHEDULE':
            return {
                ...state,
                scheduled_reviews: {
                    ...state.scheduled_reviews,
                    data: state.scheduled_reviews.data.filter(
                        review => review.id !== action.payload
                    )
                }
            };
        default :
            return{
                ...state
            }
    }
}

export const getSocialScheduledReview = (state = {}, action) => {
    switch(action.type){
        case 'GET_SOCIAL_REVIEW' :
            return{
                ...state,
                scheduled_review: action.payload
            }
        default :
            return{
                ...state
            }
    }
}